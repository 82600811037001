import React from "react"

const SIBEducatorsPageEngagement = (props) => {
  return (
    <>
      <section className="section bg-paper">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <div className="content is-large">
                <h2 className="heading-large">Engagement Challenge</h2>
                <p>
                  Of course, Shakespeare’s language, being in places archaic,
                  stylized, riddlesome and multi-layered, can be daunting for
                  many students. And, as an educator, you will know that the
                  challenge of teaching Shakespeare in the modern classroom is
                  not just confined to tricky language. All the clever insights,
                  idioms, puns, and literary devices in the world will be lost
                  to the contemporary student if the material is not presented
                  in a way that engages them. Many of today’s students are
                  simply turned off when presented with reams of text on a page.
                </p>
                <figure className="quote is-blue is-small">
                  <blockquote>
                    <p>
                      They like to parallel process and multi-task. They prefer
                      their graphics before their text rather than the
                      opposite... They are used to the instantaneity of
                      hypertext, downloaded music, phones in their pockets, a
                      library on their laptops, beamed messages and instant
                      messaging.
                    </p>
                  </blockquote>
                  <figcaption>
                    — Educational thought-leader, Marc Prensky
                  </figcaption>
                </figure>
                <p>
                  Educators today also cite the difficulty of stimulating
                  students’ interest in what is often perceived as a more
                  “traditional” element of the curriculum. The question “How is
                  Shakespeare relevant to me?” is not unheard of... But the
                  weight of opinion for generations is well summed up by
                  Shakespeare’s contemporary Ben Johnson who said that
                  Shakespeare was “for all time”. His themes and explorations of
                  the human condition are universal. But without accessibility,
                  their relevance may never be revealed to the student...
                </p>
                <figure className="quote is-blue is-small">
                  <blockquote>
                    <p>He was not of an age but for all time...</p>
                  </blockquote>
                  <figcaption>— Ben Johnson</figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SIBEducatorsPageEngagement
