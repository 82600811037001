import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const SIBEducatorsPageIntro = (props) => {
  return (
    <>
      <section className="page-header bg-paper bg-tear-white">
        <div className="container">
          <div className="columns">
            <div className="column is-8-desktop is-offset-2-desktop">
              <h1>An ideal resource for educators</h1>
              <p>
                Are you teaching Shakespeare to your students this year? If so,
                Shakespeare In Bits is an excellent way to bring the Bard to
                life in your English class.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-tear-paper">
        <div className="container">
          <div className="columns">
            <div className="column is-6 is-offset-2">
              <div className="content">
                <p>
                  Barely a person in the English-speaking world has not heard of
                  William Shakespeare and his works are universally recognized
                  as an unparalleled trove of literary accomplishment. After
                  all, they have stood the test of four centuries! Most
                  educators agree that their presence in the sphere of English
                  language education is unlikely to diminish any time soon.
                </p>
                <figure className="quote is-primary">
                  <blockquote>
                    <p>
                      The greatest genius, that perhaps human nature has yet
                      produced, our myriad-minded Shakespeare.
                    </p>
                  </blockquote>
                  <figcaption>— ST Coleridge</figcaption>
                </figure>
              </div>
            </div>
            <div className="column">
              <StaticImage
                src="../../../scss/img/image-william-shakespeare.jpg"
                alt="William Shakespeare"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SIBEducatorsPageIntro
