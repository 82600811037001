import React from "react"
import { Link } from "gatsby"

import checkCircleSVG from "../../../scss/img/icon-check-circle.svg"
import devicesSVG from "../../../scss/img/icon-devices.svg"
import optionsSVG from "../../../scss/img/icon-options.svg"
import thumbUpSVG from "../../../scss/img/icon-thumb-up.svg"
import tagSVG from "../../../scss/img/icon-tag.svg"
import loopSVG from "../../../scss/img/icon-loop.svg"

const SIBEducatorsPageBenefits = (props) => {
  return (
    <>
  <section className="section bg-paper bg-tear-yellow">
    <div className="container">

      <div className="columns mb-5">
        <div className="column is-8 is-offset-2 has-text-centered">
          <div className="content">
            <h2>Product Benefits</h2>
            <p>Key product benefits of Shakespeare In Bits — the only study guide you will need to teach, understand and appreciate Shakespeare's most celebrated works.</p>
          </div>
        </div>
      </div>

      <div className="columns is-desktop">
        <div className="column">
          <div className="box icon-box icon-box-feature is-yellow">
            <img className="image" src={checkCircleSVG} alt="Academically Rigorous"/>
            <h3 className="heading-medium">Academically Rigorous</h3>
            <p>All our titles are academically rigorous, featuring complete, unabridged, standard play text and professional synchronized audio featuring renowned Shakespearean actors. The accompanying learning support features, including comprehensive notes and analyses, have been created by a team of highly qualified subject matter experts and instructional designers.</p>
          </div>
          <div className="box icon-box icon-box-feature is-yellow">
            <img className="image" src={devicesSVG} alt="Anywhere Anytime Access"/>
            <h3 className="heading-medium">Anywhere Anytime Access</h3>
            <p>With “anywhere anytime” access to the program, students can revise what has been done in class, as well as study ahead on school computers or their own devices — all at their own pace. Because Shakespeare In Bits is now completely delivered online, it is device-agnostic and ideally suited to user environments where a variety of devices are in use including phones, tablets, laptops, and desktops.</p>
          </div>
          <div className="box icon-box icon-box-feature is-yellow">
            <img className="image" src={optionsSVG} alt="Multiple Learning Settings"/>
            <h3 className="heading-medium">Multiple Learning Settings</h3>
            <p>Shakespeare In Bits Live! can be used by educators in-class as part of an organized lesson and by students — working individually or in groups. In addition, students can log on at home to access the plays using the same account — ensuring flexible access to the materials being taught.</p>
          </div>
        </div>
        <div className="column">
          <div className="box icon-box icon-box-feature is-yellow">
            <img className="image" src={thumbUpSVG} alt="Hassle Free!"/>
            <h3 className="heading-medium">Hassle Free!</h3>
            <p>A convenient, hassle-free way to get online access to our plays without any downloads, installations, or IT involvement. All you need is an internet connection, a standard browser and audio speakers or headphones to provide our engaging digital renditions of the Bard’s most celebrated plays to your students. The sign-up process is straightforward — your class or school can be up and running with 24/7 access within 24 hours!</p>
          </div>
          <div className="box icon-box icon-box-feature is-yellow">
            <img className="image" src={tagSVG} alt="Value for Money"/>
            <h3 className="heading-medium">Value for Money</h3>
            <p>Shakespeare In Bits is inexpensive to license with per student yearly access typically costing less than half the price of a standard hardcopy text! This ensures that Shakespeare In Bits plays remain an accessible and affordable teaching and learning solution for all schools, big or small. There’s no need to pay for hardcopies when your students can access the texts for a fraction of the price and with more value-added features!</p>
          </div>
          <div className="box icon-box icon-box-feature is-yellow">
            <img className="image" src={loopSVG} alt="Continuous Improvement"/>
            <h3 className="heading-medium">Continuous Improvement</h3>
            <p>Subscribers to Shakespeare In Bits will enjoy unrestricted access to any product updates or enhancements for the duration of subscription — at no extra charge. This is guaranteed because of our move to a web-based distribution model.</p>
          </div>
        </div>
      </div>

      <div className="columns mt-5">
        <div className="column is-8 is-offset-2 has-text-centered">
          <div className="content">
            <p className="heading-tagline">Interested in a subscription?</p>
            <p><Link className="text-link" to="/pricing">See Pricing</Link></p>
          </div>
        </div>
      </div>

    </div>
  </section>
 
    </>
  )
}

export default SIBEducatorsPageBenefits
