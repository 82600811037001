import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const SIBEducatorsPageSolution = (props) => {
  return (
    <>
      <section className="section bg-tear-paper">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <div className="content is-large">
                <StaticImage src="../../../scss/img/our-solution.png" alt="Our Solution" />
                <h2 className="heading-large">Our Solution</h2>
                <p>
                  So how do we ensure that Shakespeare's works — a resource so
                  rich in literature, philosophy, psychology, and human life
                  itself — are not lost to these "digital natives"?
                </p>
                <h3 className="heading-small is-primary">
                  Crossing the Digital Divide
                </h3>
                <p>
                  Shakespeare In Bits aims to address this potential disconnect
                  by blending technology and content to provide a more
                  stimulating, contemporary learning experience. Graphics,
                  audio, and text combine to provide users with multiple
                  cognitive inputs while on-demand rollover notes, clickable
                  in-line translations, character bios etc also provide a degree
                  of interactivity that keeps the student more fully engaged
                  with the text. Importantly, this approach also allows the
                  students to interact with challenging words or phrases in
                  their original context, without resorting to “dumbed down”
                  modern “translations”.
                </p>
                <h3 className="heading-small is-primary">
                  Pedagogical Perspective
                </h3>
                <p>
                  The combination of animation, audio, and text delivered by
                  each Shakespeare In Bits title also allows students to process
                  information in a way that best suits their learning style.
                  Multiple intelligence theorists such as Howard Gardner have
                  argued that traditional educational methods best suit learners
                  with a strong verbal or logical-mathematical intelligence.
                  With Shakespeare In Bits, learners with a strong visual,
                  auditory or kinesthetic intelligence are also catered to,
                  through the rich graphic, aural, and interactive elements of
                  the software.
                </p>
                <p>
                  Other influential thinkers, such as developmental psychologist
                  Lev Vygotsky, have argued that active participation and social
                  interaction can maximize learning outcomes in the classroom.
                  Cognizant of this, Shakespeare In Bits allows students and
                  teachers the flexibility to use the product on their own in a
                  self-paced environment, or in a more collaborative group
                  settings via a projector in the classroom.
                </p>
                <p>
                  Our school licensing also facilitates flipped classroom
                  approaches, with students welcome to access the resources from
                  home, if required by their school.
                </p>
              </div>
            </div>
          </div>

          <div className="callout-panel">
            <div className="content">
              <h3 className="heading-medium">Accessible and Engaging</h3>
              <p>
                The aim of Shakespeare In Bits is to provide a teaching resource
                that make the Bard’s plays as accessible and engaging as
                possible. We believe that this is essential to help students of
                diverse learning styles and abilities to discover and truly
                appreciate the works of the most celebrated playwright in
                history.
              </p>
              <p>
                <strong>
                  Quite literally, the Bard hasn't been this easy to enjoy in
                  400 years!
                </strong>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SIBEducatorsPageSolution
