import React from "react"

import Layout from "../components/common/layout/layout"
import Seo from "../components/common/layout/seo"

import SIBEducatorsPageIntro from "../components/frontend/educatorspage/intro"
import SIBEducatorsPageEngagement from "../components/frontend/educatorspage/engagement"
import SIBEducatorsPageSolution from "../components/frontend/educatorspage/solution"
import SIBEducatorsPageBenefits from "../components/frontend/educatorspage/benefits"

import SIBCommonTestimonials from "../components/frontend/common/testimonials"
import SIBCommonSignupCTA from "../components/frontend/common/signupcta"

const EducatorsPage = ({ data }) => {
  return (
    <Layout showFrontendLinks={true}>
      <Seo title="Shakespeare In Bits - For Educators" />
      <SIBEducatorsPageIntro/>
      <SIBEducatorsPageEngagement/>
      <SIBEducatorsPageSolution/>
      <SIBEducatorsPageBenefits/>
      <SIBCommonTestimonials/>
      <SIBCommonSignupCTA/>
    </Layout>
  )
}

export default EducatorsPage